<template>
	<div id="app">
		<test></test>
		<img src="./images/banner5@1x.png" style="width: 100%;" />
		<h1>中小微企业经营贷</h1>
		<div class="cont">
			<h3>中小微企业经营贷产品</h3>
			<img src="./images/zp_18.png" />
			<p>面向中小微企业主提供的经营性贷款产品</p>
			<ul>
				<li v-for="(item,index) in list.slice(0,1)" :key="index" v-on:mouseover="changeActive(index,$event)" v-on:mouseout="removeActive(index)">
					<img src="./images/img.png" alt="">
					<div class="bg"></div>
					<div class="text" v-if="index==0" v-show="show">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==0" v-show="hide">
						<img :src="item.contentImg">
						<p>扫码了解更多</p>
					</div>
					<!-- <div class="text" v-if="index==1" v-show="show2">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==1" v-show="hide2">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div>
					<div class="text" v-if="index==2" v-show="show3">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==2" v-show="hide3">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div>
					<div class="text" v-if="index==3" v-show="show4">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==3" v-show="hide4">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div> -->
					<div class="txt">
						<h4>{{item.contentTitle}}</h4>
						<p  v-html='item.contentDetails'></p>
<!-- 						<button v-if="index==0" v-show="show">在线申请</button>
						<button v-if="index==0" class="on" v-show="hide">在线申请</button> -->

					</div>
				</li>
			</ul>
			<div class="product">
				<h3>产品优势</h3>
				<img src="./images/zp_18.png" />
				<div class="ul">
					<div class="li">
						<img src="./images/xficon_03.png" />
						<h3>息费透明</h3>
						<p>根据风险匹配度匹配利率<br>借款费用及时告知，无隐形费用</p>
					</div>
					<div class="li">
						<img src="./images/xficon_05.png" />
						<h3>安全高效</h3>
						<p>个人信息全程加密<br>保障安全</p>
					</div>
					<div class="li">
						<img src="./images/xficon_07.png" />
						<h3>申请便捷</h3>
						<p>线上申请，一次办理</p>
					</div>
					<div class="li">
						<img src="./images/xficon_09.png" />
						<h3>额度循环</h3>
						<p>授信额度，循环使用</p>
					</div>
				</div>				
			</div>
			
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
	inject:['reload'],
	  data() {
		return {
		  inActive: false,
		  list:[],
		  show:true,
		  hide:false,
		  show2:true,
		  hide2:false,
		  show3:true,
		  hide3:false,
		  show4:true,
		  hide4:false,
		}
	  },
	  created(){
		   if (location.href.indexOf("#reloaded") == -1) {
			  location.href = location.href + "#reloaded";
			  location.reload();
		  }
		var that = this
		this.$axios.post('/cms/returnAll/officialWebsite')
		.then(function (res) {
			console.log(res);
			 that.list = res.data.categoryList[2].contentList
		})
	  
	  },
	  methods: {
		  // build(){
		  // 		  var that = this;
		  // 		  that.$router.push('/build');
		  // },
		changeActive(index,$event){
				 console.log($event)
				 if(index==0){
					 this.hide = true
					 this.show = false
				 }
				 if(index==1){
					 this.hide2 = true
					 this.show2 = false
				 }
				 if(index==2){
					 this.hide3 = true
					 this.show3 = false
				 }
				 if(index==3){
					 this.hide4 = true
					 this.show4 = false
				 }
				 
		    // $event.currentTarget.className="on";
		 },
		 removeActive(index){
				  if(index==0){
					  this.hide = false
					  this.show = true
				  }
				  if(index==1){
					  this.hide2 = false
					  this.show2 = true
				  }
				  if(index==2){
					  this.hide3 = false
					  this.show3 = true
				  }
				   if(index==3){
					  this.hide4 = false
					  this.show4 = true
				   }
		 }
	  },
	
	
	}
</script>

<style>
	button{
		outline: none;
	}
	.cont ul li .txt .on{
		background-color: #ea0029;
		color: #fff;
	}
	.cont ul li .centers{
		text-align: center !important;
		width:86%
	}
	.cont ul li .centers img{
		width: 150px;
		height: auto;
	}
	#app>h1{
		font-size: 40px;
		letter-spacing:5px;
		font-weight: 300;
		position: absolute;
		top:30%;
		left: 50%;
		transform: translateX(-50%);
		color: #fff;
	}
	.Swindle .sw_div ul li .h_tit .kf{
		margin-right: 20px;
	}
	.Swindle .sw_div ul li .h_tit .left{
		padding-top: 20px;
		margin-left: 30px;
		flex: 6;
	}
	.Swindle .sw_div ul li .h_tit li img{
		flex: 0.2;
	}
	.Swindle .sw_div ul li .h_tit>h3{
		margin-bottom: 30px;
	}
	
	.Swindle .sw_div ul li .h_tit p{
		color: #fff;
		line-height: 2;
	}
	.Swindle .sw_div ul li h2{
		flex: 0.5;
		font-size: 30px;
		line-height: 50px;
	}
	.Swindle .sw_div ul li .h_tit{
		flex: 5;
		text-align: left;
		color: #fff;
	}
	.Swindle .sw_div ul li{
		margin-bottom: 30px;
		display: flex;
		justify-content: center;
		
	}
	.Swindle .sw_div{
		width: 65%;
		/* border: 1px solid #fff; */
		margin: 5% auto;
		padding: 2% 0;
	}
	.Swindle>span{
		display: block;
		width: 40px;
		margin: 15px auto;
		height: 2px;
		background-color: #ea0029;
	}
	.Swindle h3{
		color: #fff;
	}
	.Swindle{
		background: url(./images/bg2@1x.png) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		color: #fff;
		padding: 5% 0;
	}
	.product ul{
		overflow: hidden;
		width: 65%;
		margin: 20px auto;
	}
	.product ul li{
		width: 48%;
		height: 140px;
		margin: 1%;
		float: left;
		display: flex;
		justify-content: center;
	}
	.product ul li img{
		flex: 0.4;
		
	}
	.product ul li .r_txt{
		flex: 3.5;
		text-align: left;
		margin-left: 20px;
		padding-top: 20px;
		
	}
	body{
		background-color: #F3f3f3
	}
	.product{
		background-color: #fff;
		padding: 5% 0;
	}
	.ul{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 80px;
		width: 65%;
		margin:40px auto 0 auto ;
	}
	.ul .li{
		flex: 2;
		margin: 2%;
		
	}
	.ul .li h3{
		color: #3B3B3B;
	}
	.ul .li img{
		width: 50%;
		margin-bottom: 10px;
	}
	.cont>img{
		width: 50px;
	}
	.cont ul li .txt{
		margin-top: 350px;
		text-align: left;
		/* height: 113px; */
		border:1px solid #f4f6f8;
		padding:4%;				
	}
	.cont ul li .txt button{
		margin-top: 20px;
		font-size: 14px;
		border: 1px solid #ea0029;
		background-color: #fff;
		border-radius: 30px;
		color: #ea0029;
		padding: 15px 25px;
		position: absolute;
		bottom: 20px;
		width: 91%;
	}
	.cont>ul li .txt h4{
		font-weight: normal;
		font-size: 18px;
		color: #3B3B3B;
	}
	.cont>ul li .text{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		color: #fff;
		padding: 7%;
		text-align: left;
	}
	.cont>ul li .text p{
		color: #fff;
		margin-top: 30px;
	}
	.cont>ul li .text h3{
		font-weight: normal;
		color: #fff
	}
	.cont>ul li .text h1{
		margin-top: 20px;
	}
	.cont{

		/* width: 1200px; */
		margin: 40px auto;
	}
	.cont>h3{
		margin: 0;
		color: #333
	}
	.cont p{
		font-size: 12px;
		color: #666;
		margin-top: 10px;
	}
	.cont>ul{
		width: 65%;
		margin-bottom: 60px;
		margin: 30px auto;
		display: flex;
		justify-content: center;
	}
	.cont>ul li{
		width: 75%;
		background-color: #fff;
		position: relative;
		/* flex: 2; */
		margin: 1%;
		padding-bottom: 20px;
	}
	.cont>ul li>img{
		width: 100%;
		height: 350px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 8;
	}
	.cont>ul li .bg{
		width:100%;
		height: 350px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	.cont>ul li:first-child .bg{
		background:rgba(0,149,255,0.5);
	}
	.cont>ul li:nth-child(2) .bg{
		background:rgba(255,53,0,0.5);
	}
	.cont>ul li:nth-child(3) .bg{
		background:rgba(255,3,18,0.5);
	}
	.cont>ul li:nth-child(4) .bg{
		background:rgba(255,2,121,0.5);
	}
</style>
